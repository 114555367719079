/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'AccountSideNav',
        import: () => import('@stories/Widgets/AccountSideNav/AccountSideNav'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BackToTop',
        import: () => import('@stories/Components/Buttons/BackToTop/BackToTop'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Breadcrumbs',
        import: () => import('@stories/Widgets/Breadcrumbs/Breadcrumbs'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CaseStudyCarousel',
        import: () => import('@stories/Widgets/CaseStudyCarousel/CaseStudyCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CaseStudyHeroBanner',
        import: () => import('@stories/Widgets/Heroes/CaseStudyHeroBanner/CaseStudyHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CaseStudyListing',
        import: () => import('@stories/Widgets/CaseStudyListing/CaseStudyListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CategoryCardListing',
        import: () => import('@stories/Widgets/CategoryCardListing/CategoryCardListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CategorisedFAQs',
        import: () => import('@stories/Widgets/CategorisedFAQs/CategorisedFAQs'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ClickThroughGallery',
        import: () => import('@stories/Widgets/ClickThroughGallery/ClickThroughGallery'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContentCards',
        import: () => import('@stories/Widgets/ContentCards/ContentCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ClickToExpand',
        import: () => import('@stories/Widgets/ClickToExpand/ClickToExpand'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContentCards',
        import: () => import('@stories/Widgets/ContentCards/ContentCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CtaBanner',
        import: () => import('@stories/Widgets/Heroes/CtaBanner/CtaBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CtaCards',
        import: () => import('@stories/Widgets/CtaCards/CtaCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'DeleteAccountForm',
        import: () => import('@stories/Components/Form/DeleteAccountForm/DeleteAccountForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EmbeddedPdfLink',
        import: () => import('@stories/Widgets/EmbeddedPdfLink/EmbeddedPdfLink'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FAQs',
        import: () => import('@stories/Widgets/FAQs/FAQs'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FeaturedNews',
        import: () => import('@stories/Widgets/FeaturedNews/FeaturedNews'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Global/Footer/Footer'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Header',
        import: () => import('@stories/Widgets/Global/Header/Header'),
        config: {
            hydrate: 'eager',
        },
    },
    {
        name: 'HomeHeroBanner',
        import: () => import('@stories/Widgets/HomeHeroBanner/HomeHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerPageHeroBanner',
        import: () => import('@stories/Widgets/Heroes/InnerPageHeroBanner/InnerPageHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerPageTextBanner',
        import: () => import('@stories/Widgets/Heroes/InnerPageTextBanner/InnerPageTextBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InPageNav',
        import: () => import('@stories/Widgets/InPageNav/InPageNav'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'KeyBenefits',
        import: () => import('@stories/Widgets/KeyBenefits/KeyBenefits'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LightboxMedia',
        import: () => import('@stories/Components/LightboxMedia/LightboxMedia'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LoginForm',
        import: () => import('@stories/Widgets/LoginForm/LoginForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Media',
        import: () => import('@stories/Widgets/Media/Media'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsDetailHeroBanner',
        import: () => import('@stories/Widgets/Heroes/NewsDetailHeroBanner/NewsDetailHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsListing',
        import: () => import('@stories/Widgets/NewsListing/NewsListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NumberedSignpostCardListing',
        import: () => import('@stories/Widgets/NumberedSignpostCardListing/NumberedSignpostCardListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProductDetail',
        import: () => import('@stories/Widgets/ProductDetail/ProductDetail'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProductSelectionCarousel',
        import: () => import('@stories/Widgets/ProductSelectionCarousel/ProductSelectionCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProductListing',
        import: () => import('@stories/Widgets/ProductListing/ProductListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Quote',
        import: () => import('@stories/Widgets/Quote/Quote'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Register',
        import: () => import('@stories/Widgets/Register/Register'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RegisterForm',
        import: () => import('@stories/Components/Form/RegisterForm/RegisterForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ResourcesCarousel',
        import: () => import('@stories/Widgets/ResourcesCarousel/ResourcesCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ScrollAnchor',
        import: () => import('@stories/Widgets/ScrollAnchor/ScrollAnchor'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Search',
        import: () => import('@stories/Widgets/Search/Search'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SeminarListing',
        import: () => import('@stories/Widgets/SeminarListing/SeminarListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SearchResultListing',
        import: () => import('@stories/Widgets/SearchResultListing/SearchResultListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SidebarStickyButtons',
        import: () => import('@stories/Widgets/SidebarStickyButtons/SidebarStickyButtons'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContent',
        import: () => import('@stories/Widgets/SplitContent/SplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SystemBuildUp',
        import: () => import('@stories/Widgets/SystemBuildUp/SystemBuildUp'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Table',
        import: () => import('@stories/Widgets/Table/Table'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TechnicalDocumentListing',
        import: () => import('@stories/Widgets/TechnicalDocumentListing/TechnicalDocumentListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VacancyDetailHeroBanner',
        import: () => import('@stories/Widgets/Heroes/VacancyDetailHeroBanner/VacancyDetailHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VacancyListing',
        import: () => import('@stories/Widgets/VacancyListing/VacancyListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VideoCardCarousel',
        import: () => import('@stories/Widgets/VideoCardCarousel/VideoCardCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VideoListing',
        import: () => import('@stories/Widgets/VideoListing/VideoListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CtaList',
        import: () => import('@stories/Widgets/CtaList/CtaList'),
        config: {
            hydrate: 'in-view',
        },
    },
];
